@import "../utilities.scss";

.subscription-responsible-container {
  width: 100vw;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  overflow-x: hidden;

  .subscription-responsible-banner {
    height: auto;
    width: 100vw;
    position: absolute;
    top: -70px;
    left: 0;
    margin: 0 auto;
  }

  .subscription-responsible-logo {
    width: 400px;
    margin-top: 100px;
  }

  .content-wrapper {
    display: flex;
    flex-direction: column;
    width: 550px;
    align-items: center;

    .subscription-responsible-title {
      @include font-bold;
      margin: 10px 0 0;
      font-size: 40px;
    }

    .subscription-responsible-text {
      @include font-light;
      font-size: 25px;
      margin: 10px 0 0;
      width: 100%;
    }

    .subscription-responsible-text-2 {
      margin: 5px 0 0;
      @include font-light;
      font-size: 25px;
      width: 100%;
    }


    .subscription-responsible-button {
      border-radius: 8px;
      border: 3px solid $color-blue;
      background: $color-white;
      width: 100%;
      padding: 15px 0;
      font-size: 25px;
      color: #007db3;
      @include font-bold;
      cursor: pointer;
      margin: 15px auto 20px auto;
    }
  }
}
