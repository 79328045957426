@import "../utilities.scss";

.secure-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 40px;
  width: 90%;
  margin: auto;
  height: fit-content;

  .secure-left-wrapper {
    box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
    display: flex;
    flex-direction: column;
    border-radius: 5px;
    width: 100%;
    height: 100%;
    align-items: center;
    padding: 20px 0;


    .secure-second-title {
      @include font-bold;
      max-width: 90%;
      font-size: 28px;
      text-align: center;
    }

    .secure-text {
      @include font-light;
      max-width: 80%;
      text-align: center;
      font-size: 22px;
    }

    .secure-text-visit {
      margin-top: 40px;
    }

    .secure-digit-code {
      margin-top: 30px;
      @include font-bold;
      font-size: 50px;
      text-align: center;
    }

    .secure-divider-container {
      display: flex;
      position: relative;
      align-items: center;
      justify-content: center;
      margin-top: 60px;
      width: 100%;

      .secure-pink-OR {
        padding: 15px 30px;
        background-color: $color-pink;
        color: $color-white;
        border-radius: 5px;
        position: absolute;
        @include font-medium;
        font-size: 20px;
      }

      .secure-divider {
        width: 100%;
        min-height: 2px;
        background-color: $color-pink;
      }
    }
  }

  .secure-right-wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: auto 0;

    .secure-qr-code {
      width: 90%;
      height: auto;
    }
  }
}