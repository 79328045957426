@import "../utilities.scss";

.inky-switch-modal-container {
  display: flex;
  flex-direction: column;
  position: fixed;
  width: 100vw;
  height: 100vh;
  min-height: 100vh;
  left: 0;
  top: 0;
  z-index: 15;
  justify-content: center;
  align-items: center;
  background: url("../assets/Library Hero_Illustration_Faded.jpg") center center / cover no-repeat;

  .switch-modal {
    display: flex;
    flex-direction: column;
    width: 95%;
    max-width: 500px;
    height: auto;
    background-color: $color-white;
    border-radius: 10px;
    position: relative;
    overflow: hidden;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  }

  .switch-modal-header-wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: fit-content;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .switch-modal-background {
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    display: flex;

    @media screen and (max-width: 500px) {
      display: none;
    }
  }

  .switch-modal-background-mobile {
    background-color: #ec1587;
    display: none;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;

    @media screen and (max-width: 500px) {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  .switch-header {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px 0;
    z-index: 10;
    color: $color-white;
    font-size: 25px;
    @include font-bold;
  }

  .switch-message {
    font-size: 20px;
    width: 80%;
    display: flex;
    margin-left: auto;
    margin-right: auto;
    margin-top: 20px;
    @include font-light;
  }

  .switch-message-margin-both {
    margin: 30px auto 40px auto;
  }

  .switch-second-message {
    margin: 20px auto 30px auto;
  }

  .switch-button {
    border-radius: 8px;
    border: 3px solid $color-blue;
    background: $color-white;
    width: 80%;
    padding: 10px 0;
    font-size: 25px;
    color: #007db3;
    @include font-bold;
    cursor: pointer;
    margin: auto auto 20px auto;
  }

  .switch-close-button-wrapper {
    width: 100%;
    border: none;
    border-top: 2px solid $color-gray-mid;
    margin: 10px 0 0;
    color: $color-text-secondary;
    background-color: $color-gray-light;
    font-size: 18px;
    @include font-bold;
    cursor: pointer;
    min-height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    overflow: hidden;

    .background-close {
      width: 100%;
      position: absolute;
      top: 0;
      height: auto;
      z-index: 9;
      transform: rotate(180deg);
    }

    .nintendo-close-wrapper {
      width: fit-content;
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 6px;
      z-index: 10;

      .nintendo-text-border {
        position: absolute;
        width: 100%;
        background-color: $color-text-secondary;
        bottom: 0;
        min-height: 2px;
      }
    }

  }

  .switch-return-payment-button-wrapper {
    width: 85%;
    border: 2px solid $color-blue;
    @include font-bold;
    margin: auto auto 0 auto;
    color: $color-blue;
    background-color: $color-white;
    font-size: 18px;
    cursor: pointer;
    min-height: 60px;
    display: flex;
    border-radius: 5px;
    justify-content: center;
    align-items: center;
    position: relative;
    overflow: hidden;
  }
}