@import "../utilities.scss";

.payment-methods-container {
  display: flex;
  flex-direction: row;
  width: 100vw;
  height: auto;
  justify-content: center;
  margin-top: 30px;

  .payment-methods-column {
    display: flex;
    flex-direction: column;
    width: 60%;

    .payment-method {
      display: flex;
      grid-template-columns: 40px auto 50px;
      align-items: center;
      border: 2px solid $color-gray-mid;
      padding: 3px 10px;
      border-radius: 7px;
      cursor: pointer;
      background-color: transparent;


      .card-icon {
        fill: $color-navy;
        width: 40px;
      }

      .payment-method-text {
        color: $color-navy;
        font-size: 20px;
        width: 100%;
        display: flex;
        padding-left: 10px;
      }

      .forward-icon {
        width: 50px;
        transform: rotate(180deg);
        fill: $color-navy;
      }
    }

    .payment-method-active {
      border: 4px solid $color-navy;
    }
  }
}