@import "../utilities.scss";

.slider-container {
  display: flex;
  flex-direction: column;
  width: 100vw;
  height: auto;
  overflow: hidden;


  .slider-text-dots-wrapper {
    display: flex;
    flex-direction: column;
    width: fit-content;
    margin: 0 auto 20px auto;

    .slider-text {
      font-size: 20px;
      margin-top: 30px;
    }

    .slider-dots-wrapper {
      display: flex;
      flex-direction: row;
      column-gap: 10px;
      justify-content: center;
      margin-top: 10px;

      .slider-dot {
        width: 10px;
        height: 10px;
        border-radius: 10px;
        border: 2px solid $color-white;
        background-color: $color-gray-mid;
        box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
      }

      .slider-dot-active {
        background-color: $color-pink;
      }
    }
  }

  .slider-wrapper {
    width: auto;
    min-width: 300%;
    height: auto;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
  }
}