$color-white: #fff;
$color-gray-mid: #ddd;
$color-gray-light: #efefef;
$color-gray-dark: rgba(117, 117, 117, 1);
$color-gray-progressbar: #ABABAB;
$color-gray-border-checkout: #E8E8E8;
$color-black: #000;
$color-red: #f5333f;
$color-blue: #3cc8e1;
$color-blue-progressbar: #3EC8E0;
$color-text-secondary: #757575;
$color-orange: #f2ba00;
$color-mid-orange: #f2ba00;
$color-f1: #F1F1F1;
$color-c6: #C6C6C6;
$color-purple: #c964cf;
$color-green: #14bfa1;
$color-pink: #db0058;
$color-medium-pink: #cf085e;
$color-navy: #007db3;
$color-yellow: #ffe600;


@mixin font-medium {
  font-weight: 100;
  font-family: SourceSans3-SemiBold, SourceSans3-Regular-Medium, sans-serif;
}

@mixin font-bold {
  font-weight: 100;
  font-family: SourceSans3-Bold, SourceSans3-Regular-Bold, sans-serif;
}

@mixin font-light {
  font-family: SourceSans3-Regular, SourceSans3-Regular, sans-serif;
}