@import "../utilities.scss";

.subscription-modal-wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 750px;
  overflow: hidden;
  align-items: center;
  padding-bottom: 20px;
  border-radius: 10px;
  margin: 25px auto 0;
  position: relative;
  height: fit-content;

  .subscription-tone {
    width: 100%;
    z-index: 0;
  }

  .subscription-modal-header-wrapper {
    width: 95%;
    display: flex;
    align-items: center;
    margin-top: 10px;
    justify-content: center;
    position: relative;

    .subscription-modal-header {
      font-size: 35px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      @include font-bold;
      flex: 1;
    }

    .subscription-modal-icon {
      width: 30px;
      height: 30px;
      position: absolute;
      top: 3px;
      cursor: pointer;
      right: 0;
      margin-left: auto;
    }
  }

  .subscription-modal-describe {
    width: 75%;
    text-align: center;
    margin-top: 20px;
  }

  .plans-grid {
    display: grid;
    // For three subscription plans
    //grid-template-columns: 1fr 1fr 1fr;
    grid-template-columns: 1fr;
    grid-column-gap: 20px;
    justify-items: center;
    width: fit-content;
    margin-top: 10px;

    .plan {
      display: flex;
      flex-direction: column;
      // For three subscription plans
      //width: 100%;
      width: 33%;
      background-color: white;
      border-radius: 5px;
      box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
      align-items: center;
      position: relative;
      padding-bottom: 10px;
      z-index: 2;
      cursor: pointer;
      outline: 2px solid transparent;
      overflow: hidden;
      transition: outline 0.3s ease;
      border: none;

      .plan-image {
        width: 80%;
        height: auto;
        z-index: 100;
        margin-top: 10px;
      }

      .plan-duration {
        font-size: 14px;
        @include font-medium;
        margin-top: 10px;
      }

      .plan-price-wrapper {
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: center;

        .plan-price {
          font-size: 18px;
          @include font-bold;
        }

        .plan-limit {
          font-size: 16px;
        }
      }

      .plan-discount {
        font-size: 20px;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 35px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        @include font-medium;
        color: white;
        background-color: $color-blue;
      }

      &:focus {
        outline: 3px solid $color-blue;
      }
    }

    .plan-active {
      outline: 3px solid $color-blue;
    }

    .plan-not-selected {
      background-color: #f6f6f6;
    }
  }

  .coupon-wrapper {
    display: flex;
    flex-direction: column;
    z-index: 100;
    width: 90%;
    margin: 30px auto 0;

    .coupon-text {
      font-size: 17px;
      @include font-light;
    }

    .arrow-container {
      width: fit-content;
      background-color: $color-pink;
      color: white;
      margin-top: 10px;

      .arrow-btn {
        font-size: 17px;
      }
    }

    .apply-coupon {
      width: 180px;
    }

    .enter-coupon-wrapper {
      display: flex;
      flex-direction: column;
      max-width: 65%;

      .enter-coupon-actions-wrapper {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        margin-top: 10px;
        width: 100%;

        .ui-underlined-text {
          background-color: transparent;
          margin-right: 20px;
          height: fit-content;
          border-bottom-color: transparent;
          margin-top: 10px;

          &:hover {
            border-bottom-color: $color-pink;
          }
        }

        .apply {
          width: 140px;
        }
      }
    }
  }

  .divider {
    min-height: 2px;
    height: 2px;
    width: 100%;
    background-color: $color-gray-mid;
    margin-top: 20px;
    width: 90%;
    z-index: 100;
  }

  .continue-proceed-wrapper {
    display: flex;
    flex-direction: column;
    width: 90%;
    margin: 20px auto 0;
    z-index: 100;
    align-items: center;

    .continue-proceed {
      background-color: $color-pink;
      font-size: 25px;
      @include font-medium;
      color: white;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      border-radius: 5px;
      width: 55%;
      position: relative;
      height: 50px;
      cursor: pointer;
    }

    .chevron {
      width: 35px;
      position: absolute;
      right: 20px;
      fill: white;
      transform: rotate(180deg);
    }

    .ui-underlined-text {
      margin-top: 20px;
      background-color: transparent;
      margin-right: 20px;
      height: fit-content;
      border-bottom-color: transparent;
      font-size: 18px;


      &:hover {
        border-bottom-color: $color-pink;
        color: $color-pink;
      }
    }
  }
}


.plans-container {
}