@import "./../mixins/utilities.scss";

.reader-container {
    background-color: rgba(0, 0, 0, 0);
    z-index: 100;
    overflow: hidden;
    //max-height: 100vh;
    //min-height: 100vh;
    //max-width: 100vw;
    //min-width: 100vw;
    margin: 0;
    padding: 0;
    position: fixed;
    left: 0;
    top: 0;
    height: 100vh;
    height: calc(var(--vh, 1vh) * 100);


    .Reader-Vertical {
        background-color: #000000;
        width: 100vw;
        height: 100vh;
        overflow-y: scroll;
        text-align: center;
        @include reader-scroll;

        .reader-page {
            margin: 0px auto;
        }

    }

}



.Reader {
    flex-direction: column;
    flex-flow: column;
    overflow: hidden;
    transition-duration: 0ms;
    background-color: #2e2e2e;
    width: 100%;
    max-width: 100%;
    max-height: 100%;
    min-height: 100%;
    padding: 0;
    margin: 0;
}

.reader-controls {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    pointer-events: none;
}

.reader-controls .sidepanel {
    position: absolute;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 20px;
    opacity: 0;
    transition: opacity 250ms ease-in-out;
    -webkit-transition-delay: 0.5s;
    -moz-transition-delay: 0.5s;
    -o-transition-delay: 0.5s;
    pointer-events: all;

    .inner {
        display: flex;
        flex-direction: column;
        margin: 0;
        padding: 0;
        width: 100px;
        height: 100px;
        max-width: 100%;

        .InkyPenButton {
            width: 100%;
            height: 100%;

            svg {
                width: 100%;
                height: 100%;
            }
        }
    }

    @media (hover: hover) {
        &:hover, &.visible {
            opacity: 0.8;
            transition: opacity 250ms ease-in-out;
        }
    }

    &.dark-background {
        opacity: 0.5;
        color: #ffffff;
        fill: #ffffff;

        &:hover {
            opacity: 1;
            transition: opacity 250ms ease-in-out;
        }

    }

}
@media (hover: hover){
    .reader-controls .sidepanel:hover {
        background-color: rgba(0, 0, 0, 0.2);
        cursor: pointer;
    }
}

.reader-controls .left {
    left: 0;
}
.reader-controls .right {
    right: 0;
}

.icon-strip {
    height: 70%;
}

.text-strip {
    height: 70%;
}

    .suggestedComicThumb{
        height: 100%;
        width: 100%;
    }

.reader-endCard {
    position: absolute;
    top: 0;
    background-color: #000000;
    height: 100%;
    width: 100%;
    overflow: hidden;
    color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
    .reader-endCard-inner{
        position: relative;
        justify-content: center;
        margin: auto;
        //background: blue;
        width: 650px;
        h3{
            text-align: center;
            padding-bottom: 7px;
            font-size: 18px;
        }

        h2 {
            color:#FFFFFFFF;
        }

        .buttonRow.row-cols-3.row {
            padding: 0 30px;
            margin: 0;
            display: flex;
            justify-content: center;
        }

        .row-cols-3 > * {
            flex: 0 0 33.33333%;
            max-width: 100px;
        }

        .row-cols-sm-\*.row {
            margin: 0;
        }
    }

    .buttonRow{
        margin: auto;

        flex-grow: 0;
        padding-left: 30px;
        .findMore{
            background-color: #017cbf;
            border: 1px solid #3595ca;
            border-radius: 20px;
            padding: 4px 0;
            font-size: 11px;
            font-weight: 700;
            width: fit-content;
            min-width: 75px;
            text-transform: uppercase;
        }
    }

    .suggestedComicContainer{
        padding: 15px 15px 30px 15px;
        width: 100%;

        h3{
            padding-bottom: 7px;
        }
    }




    .container {
        height: 100%;
    }

    h2 {
        text-align: center;
    }
    h3 {
        font-weight: normal;
        text-align: left;
    }

    .row {
        width: 100%;
    }

}

.vertical-endCard {

   .ReaderEndCard, .ReaderEndCard-catch-up-container{
       margin-top: 60px;
       margin-bottom: 160px;
       position: unset;
   }
}

.col-sm-6 {
    display: flex;
    justify-content: center;
}


@media only screen and (max-width: 480px) {
    .reader-endCard .reader-endCard-inner .row-cols-3 > *  {
        max-width: 90px;
        display: flex;
        justify-content: center;
    }

    .reader-endCard .reader-endCard-inner .buttonRow.row-cols-3.row  {
        padding: 0 7px;
    }

    .col-sm-\*.col {
        padding: 0 7px 0 7px;
    }
}
