@import "../utilities.scss";

.header-container {
  width: 95%;
  display: grid;
  grid-template-columns: 100px auto 100px;
  margin: 20px auto 0;
  align-items: center;
  justify-items: center;
  align-content: center;

  .secure-title {
    font-size: 35px;
    @include font-light;
  }


  .back-wrapper {
    display: flex;
    //grid-template-columns: 40px auto;
    height: fit-content;
    cursor: pointer;
    justify-content: center;
    align-items: center;
    border: none;
    background-color: transparent;

    .back-icon {
      width: 35px;
    }

    .back-text {
      @include font-bold;
      font-size: 30px;
    }
  }

  .inkypen-logo {
    max-width: 200px;
    margin: 0 auto;
  }

  .cross-wrapper {
    cursor: pointer;
    height: fit-content;
    display: flex;
    width: fit-content;
    margin: auto 0 auto auto;
    border: none;
    background-color: transparent;

    .cross-icon {
      width: 40px;
      height: 40px;
      margin-left: auto;
    }
  }
}