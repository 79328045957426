@import "./utilities.scss";

* {
  box-sizing: border-box
}

body {
  width: 100%;
  padding: 0;
  margin: 0;
  height: 100vh;
}

.light-bg {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
}

.switch-payment-container {
  //display: flex;
  //flex-direction: column;
  width: 100%;
  height: 100vh;
  overflow: hidden;
  box-sizing: border-box;
  display: grid;
  grid-template-rows: 150px auto 120px;

  @include font-light;
}

.update-payment-header-content-wrapper {
  display: flex;
  flex-direction: column;
  height: 80vh;
}

.switch-payment-container-update {
  display: flex;
  margin: auto 0;
  height: 100%;
  width: 100vw;
  justify-content: center;
  align-items: center;
}