@import "src/InkyPen/mixins/utilities";

.arrow-container {
  display: flex;
  flex-direction: row;
  width: auto;
  color: $color-pink;
  border: 2px solid $color-pink;
  height: 35px;
  overflow: hidden;
  min-height: 35px;
  cursor: pointer;
  border-radius: 5px;

  &:hover {
    .icon-wrapper .chevron-icon {
      fill: #00B9DF;
    }
  }

  .arrow-btn {
    width: 100%;
    height: auto;
    font-weight: bold;
    //text-transform: uppercase;
    display: flex;
    justify-content: center;
    //border-right: 2px solid black;
    font-size: 11px;
    padding: 2px 10px 0 10px;
    letter-spacing: 2px;
    align-items: center;
    font-family: SourceSans3-Regular, SourceSans3-Regular, serif;
  }

  .with-arrow {
    padding: 2px 0 0 10px;
  }

  .with-arrow.disabled {
    border-right: 2px solid #757575;
    background-color: #125e16;
  }

  .no-arrow {
    width: 100%;
    border-right: none;
    //padding: 3px 10px 0 10px;
  }

  .icon-wrapper {
    display: flex;
    height: 100%;
    width: auto;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    border: none;
    flex-shrink: 0;
    flex-grow: 1;

    .chevron-icon {
      width: 25px;
      transform: rotate(180deg);
      fill: #007DB3;
    }


    //@media only screen and (-webkit-min-device-pixel-ratio: 1) {
    //max-width: 30px;
    //color: #0000FF;
    //}

    .icon {
      //filter: invert(1);
      width: auto;
      transform: scaleY(1.05);
      height: 100%;
    }

    @keyframes move {
      from {
        transform: translateX(-40px);
      }
      to {
        transform: translateX(0px);
      }
    }
  }


  .text-bold {
    font-size: 14px;
    @include font-medium;
    //letter-spacing: 0;
    letter-spacing: 1px;
  }

}


.inky-button-fill {
  background-color: $color-pink;
  color: $color-white;
  border: 2px solid $color-pink;
}

.arrow-container.disabled {
  border: 2px solid #757575;
  cursor: default;

  .arrow-btn {
    background-color: #757575;
    //border-right: none;
    border-color: #757575;
    color: #ABABAB;
  }

  .icon-wrapper {
    background-color: #757575;
  }
}

.arrow-container.invert {
  //filter: invert(1)
}

.arrow-container:not(.disabled):hover {
  color: white;
  background-color: $color-pink;

  .arrow-btn {
    border: none;
    //border-right: 2px solid white;
  }

  .no-arrow {
    border-right: none;
  }

  .icon {
    //filter: invert(0);
    animation: move 300ms;
  }
}

.sign-up-to-read-button-style {
  color: $color-white;
  background-color: $color-black;
}

.not-for-sale-button-style {
  color: $color-white;
  background-color: $color-black;

  cursor: default;
  user-select: none;

  &:hover {
    cursor: default;
  }
}

.read-button-style {
  color: $color-white;
  text-shadow: 1px 1px 3px #0000007d;
  background-color: $color-pink;
}