@import "../../mixins/utilities";

.inkypen-email-wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  position: relative;

  .email-input {
    width: 100%;
    height: 40px;
    border: 2px solid #dddddd;
    border-radius: 5px;
    padding: 0 10px;
    outline: none;
    -webkit-appearance: none;
  }

  .email-input::placeholder {
    color: #757575;
  }

  .email-input::-webkit-input-placeholder {
    color: #757575;
  }

  .error {
    color: $color-red;
    width: 100%;
    display: flex;
    text-align: start;
  }

  .toggle-hide-password-icon {
    position: absolute;
    width: 30px;
    right: 10px;
    top: 5px;
    z-index: 1000;
    cursor: pointer;
    fill: $color-text-secondary;
  }
}
