@import "../utilities.scss";

.add-coupon-wrapper {
  display: flex;
  flex-direction: column;
  z-index: 100;
  width: fit-content;
  min-width: 500px;
  margin: 10px auto 0 auto;
  padding: 20px 30px;
  border: 3px solid $color-gray-light;
  border-radius: 5px;
  background-color: #f8f8f8;

  .coupon-text {
    font-size: 17px;
    @include font-light;
  }

  .arrow-container {
    width: fit-content;
    background-color: $color-pink;
    color: $color-white;

    .arrow-btn {
      font-size: 17px;
    }
  }

  .apply-coupon {
    width: 180px;
  }

  .enter-coupon-wrapper {
    display: flex;
    flex-direction: column;
    margin-top: 10px;

    .inkypen-email-wrapper {
      input {
        border-color: $color-blue !important;
      }
    }

    .enter-coupon-actions-wrapper {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      width: fit-content;
      background-color: transparent;
      border: none;
      margin-top: 10px;

      .ui-underlined-text {
        background-color: transparent;
        margin-right: 20px;
        height: fit-content;
        border-bottom-color: transparent;

        &:hover {
          border-bottom-color: $color-blue;
        }
      }

      .apply {
        width: 160px;
        padding: 20px 0;
      }

      .arrow-container .no-arrow {
        padding-top: 0;
        font-size: 14px;
      }
    }

    .messages-wrapper {
      display: flex;
      flex-direction: column;
      width: fit-content;
      margin-top: 10px;

      .message {
        color: $color-text-secondary;
        font-size: 14px;
        @include font-medium;
      }
    }
  }
}
