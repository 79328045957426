@import "../utilities.scss";

.coupon-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 20px 0;
  border-top: 2px solid $color-gray-mid;
  justify-content: center;
  max-height: 200px;

  .coupon-wrapper {
    width: 65%;
    margin: 0 auto;
    height: 100%;
    display: grid;
    grid-template-columns: 300px auto;

    .enter-coupon-wrapper {
      width: 100%;
      display: flex;
      flex-direction: column;
      row-gap: 10px;
      height: 100%;

      .have-coupon-text {
      }

      .have-coupon-button {
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        color: $color-blue;
        padding: 15px 30px 15px 15px;
        width: fit-content;
        border: 2px solid $color-blue;
        border-radius: 5px;
        background-color: $color-white;
        cursor: pointer;
        text-align: start;
      }

      .coupon-applied-wrapper {
        display: grid;
        height: 100%;
        grid-template-columns: 200px 48px;
        align-items: center;
        grid-column-gap: 10px;

        .coupon-applied-text {
          border: 2px solid $color-gray-mid;
          border-radius: 5px;
          background-color: $color-gray-light;
          height: 100%;
          display: flex;
          flex-direction: column;
          justify-content: center;
          padding-left: 20px;
          color: $color-black;
          font-size: 16px;
          @include font-medium;
        }

        .coupon-applied-button {
          display: flex;
          align-items: flex-start;
          justify-content: flex-start;
          color: $color-blue;
          width: 48px;
          height: 48px;
          border: 2px solid $color-blue;
          border-radius: 5px;
          background-color: $color-white;
          cursor: pointer;
          text-align: start;

          .coupon-applied-icon {
            width: 35px;
            fill: black;
            margin: auto;
            height: 35px;
          }
        }

      }
    }

    .price-wrapper {
      display: flex;
      flex-direction: column;
      margin-left: auto;

      .price-row {
        display: grid;
        grid-template-columns: 155px auto;
        grid-column-gap: 20px;
        font-size: 15px;

        .price-row-title {
          @include font-light;
        }

        .price-value-wrapper {
          display: flex;
          flex-direction: row;

          .price-value-number {
            @include font-light;
          }

          .price-value-limit {
            @include font-light;
          }
        }
      }

      .price-row-total {
        display: grid;
        grid-template-columns: auto auto;
        grid-column-gap: 20px;
        font-size: 30px;
        margin-top: auto;

        .price-row-title {
          @include font-bold;
        }

        .price-value-wrapper {
          display: flex;
          flex-direction: row;

          .price-value-number {
            @include font-bold;
          }

          .price-value-limit {
            @include font-light;
            margin-left: 3px;
          }
        }
      }
    }
  }


}