@import "../utilities.scss";

.payment-details-container {
  width: fit-content;
  display: flex;
  flex-direction: column;
  margin: 20px auto 0;

  .payment-details-wrapper {
    display: flex;
    flex-direction: column;
    background-color: #f8f8f8;
    min-width: 600px;
    height: auto;
    border: 2px solid $color-gray-mid;
    border-radius: 5px;
  }

  .payment-details-column-wrapper {
    display: flex;
    flex-direction: column;
    width: 95%;
    margin: 10px auto 0 auto;
    overflow: hidden;
    min-height: 150px;
    align-items: center;
    padding: 10px 0;

    .payment-details-input {
      border-radius: 3px;
      width: 100%;
      padding: 10px 10px;
      font-size: 17px;
      outline: none;
      border: 2px solid $color-gray-mid;
      @include font-medium;

      &::placeholder {
        color: $color-text-secondary;
      }
    }

    .card-details-wrapper {
      margin-top: 0px;
      border: 2px solid $color-gray-mid;
      border-radius: 3px;
      width: 100%;
      font-size: 17px;
      @include font-medium;
      display: grid;
      grid-template-columns: auto 100px 70px;

      .card-details-input {
        width: 100%;
        outline: none;
        padding: 10px 10px;
        font-size: 17px;
        border: none;
        @include font-medium;
        background-color: $color-white;

        &::placeholder {
          color: $color-text-secondary;
        }
      }
    }

    .nintendo-error {
      width: 100%;
      color: $color-red;
      font-size: 14px;
      margin-top: 10px;
      @include font-light;
    }

    .automatic-subscription-text {
      width: 100%;
      color: $color-text-secondary;
      font-size: 14px;
      margin-top: 10px;
      @include font-light;

      a {
        color: $color-blue;
      }
    }

    .subscribe-button {
      width: 100%;
      background-color: $color-orange;
      @include font-medium;
      font-size: 18px;
      outline: none;
      border: none;
      padding: 10px 0;
      border-radius: 5px;
      margin-top: 30px;
      cursor: pointer;
    }

    .subscribe-button-loading {
      background-color: $color-gray-mid;
      cursor: default;
      color: $color-text-secondary;
    }
  }

  .inputError {
    border: 3px solid red;
  }

  .inputErrorLabel {
    color: red;
  }


}

.update-card-lock-click {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1111;
}

.payment-details-container-update-card {
  margin-top: 0;

  .update-card-text {
    font-size: 20px;
    margin-top: 30px;
    @include font-light;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 10px;
  }
}
